function doAction(fetchMoreButton, e) {
  // get reference to div's
  const parentDiv = fetchMoreButton.lastElementChild;
  const baseDataSet = parentDiv.dataset

  // get data for the loader
  const url = baseDataSet.url;
  const postsPerPage = baseDataSet.postsPerPage;
  const postType = baseDataSet.postType;
  const paged = baseDataSet.paged;
  const nonce = parentDiv.nonce;
  const searchTerm = baseDataSet.searchTerm;

  // Assign data to FormData
  let formData = new FormData();
  formData.append("posts_per_page", postsPerPage);
  formData.append("paged", paged);
  formData.append("post_type", postType);
  formData.append("nonce", nonce);
  formData.append("action", "ajaxCallback");
  formData.append("search_term", searchTerm);
  let params = new URLSearchParams(formData);

  // start loader
  const fetchMoreContent = document.getElementById('dw-fetchmore-content');
  fetchMoreContent.classList.add('hide');
  const spinner = document.getElementById('fetchmore-spinner');
  spinner.classList.add('show');

  // fetch the data
  fetch(url, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    body: params
  }).then((res) => {
    return res.json()
  }).then(response => {

    if (response === 0 || response.status === 'error') {
      // if error
      return;
    }

    // stop the loader
    fetchMoreContent.classList.remove('hide');
    spinner.classList.remove('show');

    // response was success
    // hide load more if max paged atteint
    if (response.paged >= response.max) {
      fetchMoreContent.style.display = "none";
    }

    // add paged number to button
    baseDataSet.paged = response.paged

    // add posts to the div
    const fetchMore = document.getElementById('dw-fetchmore');

    // console.log('in archive response split ', splitByDivMix(response.posts));

    if (postType === 'posts') {
      // dispatch a event if it's a posts archive
      const element = document.querySelector(".posts-wrapper");
      if (element.length === 0) {
        return;
      }

      const event = new CustomEvent("dw_refresh_masonry", { detail: response.posts });

      element.dispatchEvent(event);

    } else {
      fetchMore.previousElementSibling.innerHTML += response.posts
    }


  }).catch(error => {
    console.log("error =", error);
  })
}

export default function fetchMorePosts() {

  const fetchMoreButton = document.getElementById("dw-fetchmore-activator");

  if (!fetchMoreButton) {
    return;
  }

  fetchMoreButton.addEventListener("click", (e) => doAction(fetchMoreButton))
}
