import '../scss/dw-funcs.scss';

import createCustomEventHandler from './utils/createCustomEventHandler';
import fetchMorePosts from "./components/fetchMorePosts";

// wait until the window is fully loaded to run js
window.addEventListener("load", () => {
  createCustomEventHandler();
  fetchMorePosts();
});
