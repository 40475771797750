export default function createCustomEventHandler() {
  // const event = new CustomEvent("dw_refresh_masonry", {
  //   detail:
  // });

  // const elements = document.querySelectorAll(".posts-wrapper");

  // if (elements.length === 0) {
  //   return;
  // }

  // [...elements].forEach((elem) => {
  //   elem.dispatchEvent(event);
  // });
}